import React from 'react'
import Layout from '../components/layout'
import { Container } from 'react-bootstrap'
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import SEO from '../components/SEO'

const NotFound = () => {
    const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "puerto.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    console.log(data)
    return (
        <Layout>
            <SEO
              title={"404"}
            />
            <Container className="text-center">
                <h2 className="mt-4">Ooops!, esta página no existe</h2>
                <p className="mt-4">Puedes ir a la <Link to="/">página Inicio</Link> o disfrutar de la vista</p>
                <Img className="mb-5" fluid={data.file.childImageSharp.fluid} />
            </Container>
        </Layout>
    )    
}

export default NotFound